require('./../css/carousel.css');
document.addEventListener("DOMContentLoaded", function() {
  const sliders = document.querySelectorAll(".carousel");
  sliders.forEach(function(slider) {
    var time = slider.getAttribute("data-interval");
    var items = slider.querySelectorAll(".carousel-item");
    const prev = slider.querySelector('.carousel-control-prev');
    const next = slider.querySelector('.carousel-control-next');
    const totalItems = items.length;
    var id = 0;
    items[id].classList.add("active");
    items.forEach(function(item) {
      if (item.classList.contains("active")) {
        item.style.display = "block";
      } else {
        item.style.display = "none";
      }
    });
    var go = setInterval(sliderfn, time);
    prev.addEventListener("click", function() {
      clearInterval(go);
      items[id].classList.remove("active");
      id -= 1;
      if (id == - 1) id = totalItems - 1;
      items[id].classList.add("active");
      items.forEach(function(item) {
        if (item.classList.contains("active")) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
      go = setInterval(sliderfn, time);
    });
    next.addEventListener("click", function() {
      clearInterval(go);
      items[id].classList.remove("active");
      id += 1;
      if (id == totalItems) id = 0;
      items[id].classList.add("active");
      items.forEach(function(item) {
        if (item.classList.contains("active")) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
      go = setInterval(sliderfn, time);
    });

    function sliderfn() {
      items[id].classList.remove("active");
      id += 1;
      if (id == totalItems) id = 0;
      items[id].classList.add("active");
      items.forEach(function(item) {
        if (item.classList.contains("active")) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
    }
  });
});
